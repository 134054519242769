import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DashboardLayout from "../../components/Layout/DashboardLayout";
import TrackHeader from "./header";
import TrackingTableState from "./TrackTable/TrackingTable";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthUser } from "../../redux/authSliceLogin";
import Feedback from "../../components/Feedback";
import { axiosInstanceWithCred } from "../../utils/axiosinstance";
import { fetchPersonas, setPersonas } from "../../redux/personaSliceCreation";
import TrackPageSkeleton from "../../components/Layout/Skeletons/TrackPageSkeleton";

const Container = styled.div``;

export default function Tracking() {
	const [selectRepFilter, setSelectRepFilter] = useState([]);
	const dispatch = useDispatch();
	const [selectManagerFilter, setSelectManagerFilter] = useState([]);
	const authUser = useSelector(selectAuthUser);
	const [selectPersonaFilter, setSelectPersonaFilter] = useState([]);
	const [selectDateFilter, setSelectDateFilter] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [data, setData] = useState([]);
	const [dataWithKeys, setDataWithKeys] = useState([]);
	const [showFeedback, setShowFeedback] = useState(false);
	const [feedbackData, setFeedbackData] = useState({});
	const [salesRepData, setSalesRepData] = useState([]);
	const [managerData, setManagerData] = useState([]);
	const [isLoading, setIsloading] = useState(false);

	useEffect(() => {
		const handleFetchPersona = async () => {
			try {
				const response = await dispatch(fetchPersonas());
				if (fetchPersonas.fulfilled.match(response)) {
					// console.log(response);
					const personas = response.payload;
					// console.log(personas);
					dispatch(setPersonas(personas));
				}
			} catch (err) {
				console.error("Persona Error:", err);
			}
		};
		handleFetchPersona();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleDisplayFeedback = (report) => {
		setShowFeedback(true);
		const objects = JSON.parse(report);
		setFeedbackData(objects);
		// Log the objects
		// console.log(objects);
	};

	useEffect(() => {
		const fetchData = async () => {
			setIsloading(true);
			const response = await axiosInstanceWithCred.get("/track");
			setTimeout(() => {
				try {
					setData(
						response.data.sort((a, b) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);

							// Sort in descending order (most recent date first)
							return dateB - dateA;
						})
					);
					// console.log(data);
					setIsloading(false);
				} catch (err) {
					setData([]);
					setIsloading(false);
					console.log("Fetch Track Data error", err);
				}
			}, 1000);
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const filterData = () => {
			let filteredData = data;

			if (selectManagerFilter.length > 0) {
				filteredData = filteredData.filter((item) =>
					selectManagerFilter.includes(item?.user?.manager?.full_name)
				);
			}

			if (selectRepFilter.length > 0) {
				filteredData = filteredData.filter((item) =>
					selectRepFilter.includes(item?.user.full_name)
				);
			}

			if (selectPersonaFilter.length > 0) {
				filteredData = filteredData.filter((item) =>
					selectPersonaFilter.includes(item?.persona.full_name)
				);
			}

			if (selectDateFilter.length > 0) {
				const [startDate, endDate] = selectDateFilter.map(
					(date) => new Date(date)
				);
				filteredData = filteredData.filter((item) => {
					const itemDate = new Date(item.date);
					return itemDate >= startDate && itemDate <= endDate;
				});
			}

			// Uncomment and adjust this line if you want to sort the filtered data
			// filteredData.sort((a, b) => a.score - b.score);

			setDataWithKeys(filteredData);
		};

		// console.log("selectDateFilter", selectDateFilter);

		filterData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectRepFilter,
		selectPersonaFilter,
		selectDateFilter,
		authUser,
		selectManagerFilter,
		data,
	]);

	useEffect(() => {
		// Process rep data
		const repDatas = dataWithKeys.map((rep) => rep.user);
		const uniqueNameMap = new Map();
		const uniqueRepData = repDatas.filter((rep) => {
			if (rep && !uniqueNameMap.has(rep.full_name)) {
				uniqueNameMap.set(rep.full_name, true);
				return true;
			}
			return false;
		});

		// Process manager data
		const managerDatas = dataWithKeys.map((manager) => manager?.user?.manager);
		const uniqueManagerMap = new Map();
		const uniqueManagerData = managerDatas.filter((manager) => {
			if (manager && !uniqueManagerMap.has(manager.full_name)) {
				uniqueManagerMap.set(manager.full_name, true);
				return true;
			}
			return false;
		});

		// Update state with filtered data
		setSalesRepData(uniqueRepData);
		setManagerData(uniqueManagerData);
	}, [dataWithKeys]); // Dependency array to trigger effect when dataWithKeys changes
	return (
		<DashboardLayout>
			<Container>
				{showFeedback && (
					<Feedback
						feedbackData={feedbackData}
						setShowFeedback={setShowFeedback}
						handleRestartCall={null}
					/>
				)}
				{isLoading ? (
					<TrackPageSkeleton />
				) : (
					<>
						<TrackHeader data={dataWithKeys} user={authUser} />
						<TrackingTableState
							data={data}
							salesRepData={salesRepData}
							managerData={managerData}
							authUser={authUser}
							selectRepFilter={selectRepFilter}
							selectDataFilter={selectDateFilter}
							selectManagerFilter={selectManagerFilter}
							setSelectManagerFilter={setSelectManagerFilter}
							selectPersonaFilter={selectPersonaFilter}
							setSelectRepFilter={setSelectRepFilter}
							setSelectPersonaFilter={setSelectPersonaFilter}
							setSelectDateFilter={setSelectDateFilter}
							dataWithKeys={dataWithKeys}
							setDataWithKeys={setDataWithKeys}
							handleDisplayFeedback={handleDisplayFeedback}
						/>
					</>
				)}
			</Container>
		</DashboardLayout>
	);
}
