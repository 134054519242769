export const convertToNonDecimal = (score) => {
	let numericScore;

	// Check if the score is a fraction
	if (score.includes("/")) {
		const [numerator, denominator] = score.split("/").map(Number);

		// Ensure both numerator and denominator are valid numbers
		if (isNaN(numerator) || isNaN(denominator) || denominator === 0) {
			console.error("Invalid fraction value:", score);
			return "N/A"; // Return a default value or handle the error as needed
		}

		// Convert the fraction to a percentage
		numericScore = (numerator / denominator) * 100;
	} else {
		// If not a fraction, treat it as a percentage string
		numericScore = parseFloat(score.replace("%", ""));

		if (isNaN(numericScore)) {
			console.error("Invalid score value:", score);
			return "N/A"; // Return a default value or handle the error as needed
		}
	}

	// Check if the percentage is a decimal
	if (numericScore % 1 !== 0) {
		return numericScore.toFixed(0) + "%"; // Return rounded percentage
	}

	return `${numericScore}%`; // Return integer percentage
};
